import { Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { UiCustomisationService } from './core/services/settings/ui-customisation/ui-customisation.service';
import { UiCutomiseResponseModel } from './shared/models/ui-customisation/ui-customisation.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  title = 'one-service-hub';
  constructor(private uiCustomisationService: UiCustomisationService) {}

  ngOnInit(): void {
    // this.uiCustomisationService.getUiCustomiseData(true).subscribe(() => {
    //   this.uiCustomisationService.applyCustomTheming();
    //   this.isCompleted = true;
    // });
    this.getAndApplyLocalUiCustomisation();
    this.loadAnalyticsScript();
  }

  loadAnalyticsScript() {
    if (typeof document !== 'undefined') {
      const script = document.createElement('script');
      script.src = environment.analyticsTagUrl;
      script.async = true;
      document.body.appendChild(script);
    }
  }

  getAndApplyLocalUiCustomisation() {
    try {
      if (typeof window !== 'undefined') {
        const fetchUiCustomisationLocalStorageData = localStorage.getItem(
          'uiCustomisationConfig',
        );
        const uiCustomisationLocalStorageData =
          fetchUiCustomisationLocalStorageData
            ? (JSON.parse(
                atob(fetchUiCustomisationLocalStorageData),
              ) as UiCutomiseResponseModel)
            : null;

        if (uiCustomisationLocalStorageData) {
          this.uiCustomisationService.setUiCustomisationResponseData(
            uiCustomisationLocalStorageData,
          );
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  get loadedUserDetails() {
    return this.uiCustomisationService.loadedUserDetails;
  }
  get isCompletedUI() {
    return this.uiCustomisationService.loadPublicUser;
  }
}
