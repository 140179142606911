import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { UiCustomisationService } from '@app/core/services/settings/ui-customisation/ui-customisation.service';
import { UiThemeMode } from '@app/shared/models/ui-customisation/ui-customisation.model';

@Component({
  selector: 'app-spinner',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit {
  @Input() size: '' | 'sm' | 'lg' | 'xlg' = '';
  @Input() isAI = false as boolean;
  @Input() color: '' | 'primary' | 'secondary' | 'light' | 'white' = '';

  constructor(private uiCustomisationService: UiCustomisationService) {}
  uiThemeType!: UiThemeMode;
  ngOnInit(): void {
    if (typeof window !== 'undefined') {
      this.uiCustomisationService
        .checkInLocalStorageAndSubject()
        .subscribe((value) => {
          if (value) {
            this.uiThemeType = value.themeMode;
          }
        });
    }
  }
}
