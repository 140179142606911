import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PUBLIC } from '@app/core/constants/api.constant';
import { CustomHttpResponse } from '@app/shared/models/custom-http-response.model';
import { PublicCustomTokenResponseModel } from '@app/shared/models/sso/sso.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PublicService {
  constructor(private httpClient: HttpClient) {}

  getCustomToken(): Observable<
    CustomHttpResponse<PublicCustomTokenResponseModel>
  > {
    return this.httpClient.get<
      CustomHttpResponse<PublicCustomTokenResponseModel>
    >(PUBLIC.GET_CUSTOM_TOKEN);
  }
}
