import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '@app/core/services/auth/auth.service';
import {
  catchError,
  first,
  forkJoin,
  from,
  map,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { CONSTANTS } from '../constants/constant';
import { PublicService } from '../services/public/public.service';
import { UiCustomisationService } from '../services/settings/ui-customisation/ui-customisation.service';
import { ToastService } from '../services/toast/toast.service';
import { UserDetailService } from '../services/user-detail/user-detail.service';

export const canActivate: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const authService: AuthService = inject(AuthService);
  const userDetailService: UserDetailService = inject(UserDetailService);
  const toastService: ToastService = inject(ToastService);
  const uiCustomisationService: UiCustomisationService = inject(
    UiCustomisationService,
  );

  uiCustomisationService.loadPublicUser = true;

  if (typeof window !== 'undefined') {
    uiCustomisationService.loadedUserDetails = false;
    return authService.userData.pipe(
      first(),
      switchMap((user) => {
        if (!!user?.email && !user.email.endsWith('iam.gserviceaccount.com')) {
          // Call both APIs in parallel
          return forkJoin({
            userDetails: userDetailService.getUserDetails({
              email: user.email,
            }),
            uiCustomisation: uiCustomisationService.getUiCustomiseData(),
          }).pipe(
            map(({ userDetails, uiCustomisation }) => {
              const isValid = !!(
                userDetails?.data && userDetails?.data?.userModel?.email
              );
              return { isValid, userDetails, uiCustomisation };
            }),
            tap(({ isValid, userDetails }) => {
              if (!isValid) {
                authService.redirectUrl = state.url;
                authService.redirectToLogin();
              } else {
                if (userDetails.status_code === 200) {
                  uiCustomisationService.applyCustomTheming();
                  userDetailService.emitCompactDetail(userDetails.data);
                } else {
                  authService.signOut();
                  toastService.error(userDetails.message);
                }
              }
              uiCustomisationService.loadedUserDetails = true;
            }),
            map(({ isValid }) => isValid), // Return only validity
            catchError(() => {
              uiCustomisationService.loadedUserDetails = true;

              authService.redirectUrl = state.url;
              authService.signOut('/auth');
              setTimeout(() => {
                toastService.error(CONSTANTS.MESSAGES.SOMETHING_WENT_WRONG);
              }, 1000);
              return of(false);
            }),
          );
        }

        uiCustomisationService.loadedUserDetails = true;
        authService.redirectUrl = state.url;
        authService.redirectToLogin();
        return of(false);
      }),
    );
  }

  return of(false);
};

export const canActivateIfUnauthenticated: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const authService: AuthService = inject(AuthService);
  const uiCustomisationService: UiCustomisationService = inject(
    UiCustomisationService,
  );
  const publicService: PublicService = inject(PublicService);
  const toastService: ToastService = inject(ToastService);

  uiCustomisationService.loadedUserDetails = true;

  if (typeof window !== 'undefined') {
    uiCustomisationService.loadPublicUser = false;
    return authService.userData.pipe(
      first(),
      switchMap((user) => {
        if (!user?.email || user.email.endsWith('iam.gserviceaccount.com')) {
          //User is not authenticated, try custom token sign-in
          return publicService.getCustomToken().pipe(
            switchMap((res) => {
              if (res.status_code === 200) {
                return authService.signInWithCustomToken(res.data.token);
              } else {
                toastService.error(CONSTANTS.MESSAGES.CUSTOM_TOKEN_ERROR);
                return of(null);
              }
            }),
            switchMap((res) => {
              if (res?.user) {
                return from(res.user.getIdToken()).pipe(
                  tap((token: string) => {
                    authService.setToken(token);
                    uiCustomisationService
                      .getUiCustomiseData()
                      .subscribe(() => {
                        uiCustomisationService.applyCustomTheming();
                        uiCustomisationService.loadPublicUser = true;
                      });
                  }),
                  map(() => true), //successful login
                );
              } else {
                toastService.error(CONSTANTS.MESSAGES.CUSTOM_TOKEN_AUTH_FAILED);
                uiCustomisationService.loadPublicUser = true;

                return of(false);
              }
            }),
            catchError((error) => {
              uiCustomisationService.loadPublicUser = true;
              toastService.error(error);
              return of(false);
            }),
          );
        }
        uiCustomisationService.loadPublicUser = true;
        authService.redirectToDashboard();
        return of(false);
      }),
      tap((isAuthenticated) => {
        if (!isAuthenticated) {
          uiCustomisationService.loadPublicUser = true;

          authService.redirectUrl = state.url;
          authService.redirectToDashboard();
        }
      }),
    );
  }

  return of(false);
};
