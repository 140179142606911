<div class="d-flex justify-content-center">
  <span
    *ngIf="!isAI"
    class="spinner-border"
    [ngClass]="{
      'spinner-border-lg': size === 'lg',
      'spinner-border-sm': size === 'sm',
      'spinner-border-xlg': size === 'xlg',
      'spinner__color--default': !color,
      text__primary: color === 'primary',
      'text-secondary': color === 'secondary',
      'text-light': color === 'light',
      'text-white': color === 'white'
    }"
    role="status"
    aria-hidden="true"
  ></span>
  <ng-container *ngIf="isAI">
    <img
      [src]="
        uiThemeType === 'DARK'
          ? '/assets/videos/ai-spinner-DRAK.gif'
          : '/assets/videos/ai-spinner-LIGHT.gif'
      "
      class="img-fluid"
      alt="AI Animation"
    />
  </ng-container>
</div>
