import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UiCustomisationService } from '@app/core/services/settings/ui-customisation/ui-customisation.service';
import { UiThemeMode } from '@app/shared/models/ui-customisation/ui-customisation.model';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnChanges {
  ngOnChanges() {
    this.basePath = this.isLoggedIn ? '/home' : '';
  }

  @Input() isLoggedIn = false;
  currentYear = new Date().getFullYear();
  basePath = '';

  constructor(private uiCustomisationService: UiCustomisationService) {}
  uiThemeType!: UiThemeMode;
  ngOnInit(): void {
    this.uiCustomisationService.getUiCustomiseData().subscribe((value) => {
      if (value && value.themeMode) {
        this.uiThemeType = value.themeMode;
      }
    });
  }
}
