import { CommonModule } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import navList from '@app/core/constants/nav-items';
import { FeatureManagementService } from '@app/core/services/feature-management/feature-management.service';
import { UserDetailService } from '@app/core/services/user-detail/user-detail.service';
import { NavbarModel } from '@app/shared/models/other/navbar.models';
import { filter, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  isCollapsed = false as boolean;
  navList = navList as NavbarModel[];
  currentNavList = navList[0] as NavbarModel | null;
  defaultRoute = '';
  sideBarTitle = '';
  private destroy$ = new Subject<void>();
  private currentNavList$ = new Subject<NavbarModel>();

  constructor(
    private router: Router,
    private featureService: FeatureManagementService,
    private userDetailService: UserDetailService,
  ) {
    this.userDetailService.selectedBusinessArea$
      .pipe(
        filter((value) => value !== null && value !== undefined),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.currentNavList$.next(this.currentNavList as NavbarModel);
      });
  }

  @HostListener('window:resize', ['$event'])
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  onResize(event: any) {
    const innerWidth = event ? event?.target?.innerWidth : window?.innerWidth;
    this.isCollapsed = innerWidth > 768 ? false : true;
  }

  ngOnInit() {
    if (typeof window !== 'undefined')
      this.setCurrentNavList(window.location.pathname);

    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    this.router.events.subscribe((event: Event | any) => {
      if (
        event instanceof NavigationEnd ||
        event.routerEvent instanceof NavigationEnd
      ) {
        const cEvent =
          event?.routerEvent instanceof NavigationEnd
            ? event.routerEvent
            : event;
        const matchUrl =
          cEvent.url === '/home' ? cEvent.url : cEvent.url.replace('/home', '');
        this.defaultRoute = cEvent.url === '/home' ? '' : '/home/';
        this.setCurrentNavList(matchUrl);
      }
    });

    this.currentNavList$.subscribe((value) => {
      this.checkIfHasAccess(value);
    });

    this.onResize(null);
  }

  setCurrentNavList(matchUrl: string) {
    const elem = this.navList.find((x) => matchUrl.includes(x.path));
    if (elem) {
      this.currentNavList = elem;
    } else {
      this.currentNavList = this.navList[0];
    }
    this.currentNavList$.next(this.currentNavList);
  }

  showHideNavItemsBasedOnPermission(item: {
    path: string;
    title: string;
    icon: string;
    features: string[];
    permissions: string[];
  }) {
    // return item.features && item.features.length > 0
    //   ? this.featureService.showHideFeaturesBasedOnAvailability(
    //       item.features,
    //     )
    //   : true;
    return this.featureService.validateFeaturesAndPermissions(
      item.features,
      item.permissions,
    );
  }

  checkIfHasAccess(navItem: NavbarModel) {
    const urlSegments = this.router.url.split('/');

    if (urlSegments.length > 3) {
      const currentNav = navItem.items.find(
        (nav) => nav.path === urlSegments[3],
      );

      if (currentNav) {
        const hasAccess = this.showHideNavItemsBasedOnPermission(currentNav);
        if (!hasAccess) {
          this.router.navigate(['/home/access-denied']);
        }
      }
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
