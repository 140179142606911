import { CommonModule } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { UserInfo } from '@angular/fire/auth';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import navItems from '@app/core/constants/nav-items';
import { AuthService } from '@app/core/services/auth/auth.service';
import { FeatureManagementService } from '@app/core/services/feature-management/feature-management.service';
import { SettingsService } from '@app/core/services/settings/settings.service';
import { UiCustomisationService } from '@app/core/services/settings/ui-customisation/ui-customisation.service';
import { UserDetailService } from '@app/core/services/user-detail/user-detail.service';
import { cloneDeep } from '@app/core/utils/clone-deep';
import { RolesEnum } from '@app/shared/enum/role-enum';
import { BusinessAreaModel } from '@app/shared/models/manage-business-area/business-area.model';
import {
  CreditsModel,
  PlansModel,
  UserCompactModel,
} from '@app/shared/models/manage-user/user-compact-detail.model';
import { NavbarModel } from '@app/shared/models/other/navbar.models';
import { UiCustomiseActiveEnum } from '@app/shared/models/ui-customisation/ui-customisation.model';
import { environment } from '@environments/environment';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { filter, Observable, Subject, takeUntil } from 'rxjs';
import { BusinessOrganisationDropdownComponent } from '../../business-organisation-dropdown/business-organisation-dropdown.component';
import { ChatBotComponent } from '../../chat-bot/chat-bot.component';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    CollapseModule,
    BsDropdownModule,
    ProgressbarModule,
    ChatBotComponent,
    BusinessOrganisationDropdownComponent,
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  isCollapsed = false;
  userData = null as UserInfo | null;
  userHighestRole = null as RolesEnum | null;
  isLoggedIn = false;
  isAdmin = false;
  userCompactDetails = null as UserCompactModel | null;
  userPlanDetails$: Observable<PlansModel | null>;
  navList = navItems as NavbarModel[] | [];
  backUpNavList = cloneDeep(navItems) as NavbarModel[] | [];
  currentNavList = this.navList[0] as NavbarModel | null;
  defaultRoute = '';
  userRoles: RolesEnum[] = [];
  version: string | undefined;
  logoImage: string | null = null;
  businessAreas: BusinessAreaModel[] = [];
  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private authService: AuthService,
    private userDetailService: UserDetailService,
    private settingsService: SettingsService,
    private uiCustomisationService: UiCustomisationService,
    private featuresService: FeatureManagementService,
  ) {
    if (this.authService?.userData) {
      this.authService?.userData.subscribe((user) => {
        this.isLoggedIn = !user;
        this.userData = user ?? null;
      });
    }

    this.userDetailService.userCompactDetail$
      .pipe(
        filter((value) => value !== null && value !== undefined),
        takeUntil(this.destroy$),
      )
      .subscribe((value) => {
        this.userCompactDetails = value;
        this.userHighestRole = this.userDetailService.currentUserHighestRole;
        // FIXME: [FIX-ROLES]: remove roles dependency from here, code refactoring
        this.userRoles = this.userDetailService.currentUserRoles;
        this.businessAreas =
          this.userDetailService.allBusinessAreasOfUser ?? [];
        if (this.userDetailService.isAdminOrSuperAdmin) {
          this.isAdmin = true;
        }
      });

    this.userDetailService.selectedBusinessArea$
      .pipe(
        filter((value) => value !== null && value !== undefined),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.showNavItemsBasedOnPermissions();
      });

    this.uiCustomisationService.localUiCustomiseData$
      .pipe(
        filter((value) => value !== null && value !== undefined),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        const customData = cloneDeep(
          this.uiCustomisationService.customUiCustomiseData.getValue(),
        );
        this.logoImage =
          this.uiCustomisationService.activeUiCustomisation ===
            UiCustomiseActiveEnum.CUSTOM && customData?.logoImage?.fileContent
            ? customData?.logoImage.fileContent
            : null;
      });
    this.userPlanDetails$ = this.userDetailService.userPlanDetail$;
    this.routeChangeEvent();
  }

  ngOnInit() {
    this.getVersion();
    this.onResize(null);
    this.showNavItemsBasedOnPermissions();
  }

  get getEnvironmentName(): string {
    return environment.environmentName;
  }

  routeChangeEvent() {
    if (typeof window !== 'undefined')
      this.setCurrentNavList(window.location.pathname);
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    this.router.events.subscribe((event: Event | any) => {
      if (
        event instanceof NavigationEnd ||
        event.routerEvent instanceof NavigationEnd
      ) {
        const cEvent =
          event?.routerEvent instanceof NavigationEnd
            ? event.routerEvent
            : event;
        const matchUrl =
          cEvent.url === '/home' ? cEvent.url : cEvent.url.replace('/home', '');
        this.defaultRoute = cEvent.url === '/home' ? '' : '/home/';
        const elem = this.navList.find((x) => matchUrl.includes(x.path));
        if (elem) {
          this.currentNavList = elem;
        } else {
          this.currentNavList = this.navList[0];
        }
      }
    });
  }

  setCurrentNavList(matchUrl: string) {
    const elem = this.navList.find((x) => matchUrl.includes(x.path));
    if (elem) {
      this.currentNavList = elem;
    } else {
      this.currentNavList = this.navList[0];
    }
  }

  @HostListener('window:resize', ['$event'])
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  onResize(event: any) {
    const innerWidth = event ? event?.target?.innerWidth : window?.innerWidth;
    this.isCollapsed = innerWidth > 768 ? false : true;
  }

  logout() {
    this.settingsService.orgSettingsValue.next(null);
    this.settingsService.orgSettings = null;
    this.isCollapsed = !this.isCollapsed;
    this.authService.signOut();
  }

  getProgressVal(credit: CreditsModel) {
    return credit?.remainingCredit?.remainingCredits
      ? ((credit?.remainingCredit?.remainingCredits ?? 1) / credit.quantity) *
          100
      : 0;
  }

  customRouteActive(path: string): boolean {
    return this.router.url.includes(path);
  }

  getRouterPath(nav: NavbarModel): string {
    if (
      nav.path === 'administration' ||
      nav.path === 'digital-contents' ||
      nav.path === 'settings'
    ) {
      const activeItemIndex = this.findFirstActiveItemIndex(nav);
      return (
        nav.path +
        '/' +
        nav.items[activeItemIndex == -1 ? 0 : activeItemIndex].path
      );
    }
    return nav.path;
  }

  findFirstActiveItemIndex(nav: NavbarModel): number {
    return nav.items.findIndex((item) =>
      this.filterInternalNavOnPermission(item.features, item.permissions),
    );
  }

  getVersion() {
    this.settingsService.getVersion().subscribe({
      next: (res) => {
        if (res?.status_code === 200) {
          this.version = res.data?.toString();
        }
      },
    });
  }

  showNavItemsBasedOnPermissions() {
    const updatedNavList = this.backUpNavList.filter((nav) => {
      return this.featuresService.validateFeaturesAndPermissions(
        nav.features,
        nav.permissions,
      );
    });
    this.navList = cloneDeep(updatedNavList);
    const urlSegments = this.router.url.split('/');
    if (urlSegments.length > 2) {
      const tempIndex = this.navList.findIndex(
        (nav) => nav.path === urlSegments[2],
      );
      if (tempIndex == -1) {
        this.router.navigate(['/home/access-denied']);
      }
      if (urlSegments[2] === 'access-denied') {
        this.router.navigate(['/home']);
      }
    }
  }

  filterInternalNavOnPermission(features: string[], permissions: string[]) {
    return this.featuresService.validateFeaturesAndPermissions(
      features,
      permissions,
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
