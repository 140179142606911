export interface UiCutomiseResponseModel {
  activeSetting: UiCustomiseActiveEnum;
  customSetting: UiCustomiseModel;
  defaultSetting: UiCustomiseModel;
}
export enum UiThemeMode {
  DARK = 'DARK',
  LIGHT = 'LIGHT',
}

export enum UiCustomiseActiveEnum {
  DEFAULT = 'DEFAULT',
  CUSTOM = 'CUSTOM',
}

export interface UiCustomiseModel {
  logoImage: UiCustomiseFileModel;
  faviconImage: UiCustomiseFileModel;
  themes: UiCustomiseThemeModel;
  components: UiCustomiseComponentModel;
  themeMode: UiThemeMode;
}

export interface UiCustomiseThemeModel {
  body: UiCustomisePropertiesBodyModel;
  primary: UiCustomisePropertiesPrimaryModel;
  success: UiCustomisePropertiesMinModel;
  error: UiCustomisePropertiesMinModel;
  warning: UiCustomisePropertiesMinModel;
  info: UiCustomisePropertiesMinModel;
  advanced: UiCustomisePropertiesAdvancedModel;
}
export interface UiCustomisePropertiesMinModel {
  background: string;
  foreground: string;
}

export interface UiCustomisePropertiesBodyModel {
  background: string;
  backgroundShadeOne: string;
  backgroundShadeTwo: string;
  backgroundShadeThree: string;
  foreground: string;
  foregroundShadeOne: string;
  foregroundShadeTwo: string;
  foregroundShadeThree: string;
}

export interface UiCustomisePropertiesPrimaryModel
  extends UiCustomisePropertiesMinModel {
  backgroundShadeTwo: string;
  backgroundShadeOne: string;
}

export interface UiCustomisePropertiesAdvancedModel {
  background: string;
  foreground: string;
  boxShadowColor: string;
  borderRadius: string;
}

export interface UiCustomiseComponentModel {
  header: UiCustomiseHeaderModel;
  navBar: UiCustomiseHeaderModel;
  sidebar: UiCustomiseSidebarModel;
  actionPrimary: UiCustomiseActionPrimaryModel;
  actionSecondary: UiCustomiseActionSecondaryModel;
  actionLink: UiCustomiseActionLinkModel;
  badgeOutline: UiCustomiseBadgeElementModel;
  badgeSolid: UiCustomiseBadgeElementModel;
  card: UiCustomCardModel;
  cardLabelOne: UiCustomiseComponentPropertiesModel;
  cardLabelTwo: UiCustomiseComponentPropertiesModel;
  cardLabelThree: UiCustomiseComponentPropertiesModel;
  cardIcon: UiCustomiseIconModel;
  tabs: UiCustomiseTabsModel;
  breadCrumbs: UiCustomiseBreadcrumbModel;
  pagination: UiCustomPaginationModel;
  iconWithBg: UiCustomiseIconModel;
  iconWithoutBg: UiCustomiseIconModel;
  iconBW: UiCustomiseIconModel;
  formFields: UiCustomFormFieldModel;
  checksAndRadio: UiCustomChecksAndRadioModel;
}

export interface UiCustomiseComponentPropertiesModel
  extends UiCustomisePropertiesMinModel {
  themeRef: UiCustomisePropertiesMinModel;
}

export interface UiCustomiseFileModel {
  contentType: string;
  fileContent: string;
}

export interface UiCustomCardModel extends UiCustomisePropertiesMinModel {
  secondaryText: string;
  linkText: string;
  borderWidth: string;
  borderColor: string;
  boxShadowColor: string;
  borderRadius: string;
  themeRef: {
    background: string;
    foreground: string;
    secondaryText: string;
    linkText: string;
    borderColor: string;
    boxShadowColor: string;
    borderRadius: string;
  };
}

export interface UiCustomiseActionPrimaryModel
  extends UiCustomisePropertiesMinModel {
  backgroundShadeOne: string;
  hover: string;
  active: string;
  boxShadowColor: string;
  borderRadius: string;
  themeRef: {
    background: string;
    backgroundShadeOne: string;
    foreground: string;
    hover: string;
    active: string;
    boxShadowColor: string;
    borderRadius: string;
  };
}

export interface UiCustomiseActionSecondaryModel
  extends UiCustomisePropertiesMinModel {
  hover: string;
  active: string;
  boxShadowColor: string;
  borderRadius: string;
  themeRef: {
    background: string;
    foreground: string;
    hover: string;
    active: string;
    boxShadowColor: string;
    borderRadius: string;
  };
}

export interface UiCustomiseActionLinkModel {
  foreground: string;
  hover: string;
  themeRef: {
    foreground: string;
    hover: string;
  };
}

export interface UiCustomiseBadgeElementModel
  extends UiCustomisePropertiesMinModel {
  borderRadius: string;
  themeRef: {
    background: string;
    foreground: string;
    borderRadius: string;
  };
}

export interface UiCustomiseHeaderModel extends UiCustomisePropertiesMinModel {
  labelForeground: string;
  labelBackground: string;
  secondaryText: string;
  themeRef: {
    background: string;
    foreground: string;
    labelForeground: string;
    labelBackground: string;
    secondaryText: string;
  };
}

export interface UiCustomiseSidebarModel extends UiCustomisePropertiesMinModel {
  active: string;
  hover: string;
  themeRef: {
    background: string;
    foreground: string;
    active: string;
    hover: string;
  };
}

export interface UiCustomFormFieldModel extends UiCustomisePropertiesMinModel {
  placeholderColor: string;
  borderColor: string;
  hover: string;
  focus: string;
  borderRadius: string;
  themeRef: {
    background: string;
    foreground: string;
    placeholderColor: string;
    borderColor: string;
    hover: string;
    focus: string;
    borderRadius: string;
  };
}
export interface UiCustomChecksAndRadioModel
  extends UiCustomisePropertiesMinModel {
  borderColor: string;
  hover: string;
  checked: string;
  borderRadius: string;
  themeRef: {
    background: string;
    foreground: string;
    borderColor: string;
    hover: string;
    checked: string;
    borderRadius: string;
  };
}

export interface UiCustomPaginationModel extends UiCustomisePropertiesMinModel {
  borderRadius: string;
  themeRef: {
    background: string;
    foreground: string;
    borderRadius: string;
  };
}

export interface UiCustomiseBreadcrumbModel {
  foreground: string;
  hover: string;
  active: string;
  themeRef: {
    foreground: string;
    hover: string;
    active: string;
  };
}

export interface UiCustomiseIconModel {
  background: string;
  foreground: string;
  hover: string;
  themeRef: {
    background: string;
    foreground: string;
    hover: string;
  };
}

export interface UiCustomiseTabsModel {
  foreground: string;
  hover: string;
  active: string;
  themeRef: {
    foreground: string;
    hover: string;
    active: string;
  };
}
