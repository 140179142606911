import { Injectable } from '@angular/core';
import { FeatureModel } from '@app/shared/models/feature-management/feature.model';
import { UserCompactModel } from '@app/shared/models/manage-user/user-compact-detail.model';
import {
  BehaviorSubject,
  filter,
  map,
  Observable,
  of,
  switchMap,
  take,
} from 'rxjs';
import { StartupService } from '../bootstrap/startup.service';
import { UserDetailService } from '../user-detail/user-detail.service';

type FeatureRecord = Record<string, boolean>;
@Injectable({
  providedIn: 'root',
})
export class FeatureManagementService {
  private featuresSubject = new BehaviorSubject<FeatureRecord>({});
  features$ = this.featuresSubject.asObservable();
  featureRecord: FeatureRecord = {};
  currentPermissions: string[] = [];
  beforeLoginStatus = true;

  constructor(
    private userDetailService: UserDetailService,
    private startupService: StartupService,
  ) {
    this.loadFeaturesAndPermissionInitially();
  }

  loadFeaturesAndPermissionInitially(): void {
    this.userDetailService.userCompactDetail
      .pipe(filter((value) => value !== null && value !== undefined))
      .subscribe((response) => {
        this.mapUserCompactData(response);
      });
    this.userDetailService.selectedBusinessArea$
      .pipe(filter((value) => value !== null && value !== undefined))
      .subscribe(() => {
        this.currentPermissions = this.userDetailService.currentPermissions;
      });
  }

  /**
   * to map permission and features into an array
   * @param response : it userCompactData
   */
  mapUserCompactData(response: UserCompactModel | null) {
    if (response?.userOrganisation?.purchasedProduct?.plan?.features) {
      const featureAvailableInPlan =
        response?.userOrganisation?.purchasedProduct?.plan?.features;
      this.featureRecord = this.transformFeatures(featureAvailableInPlan);
      if (this.featureRecord) {
        localStorage.setItem(
          'featureRecords',
          btoa(JSON.stringify(this.featureRecord)),
        );
      }
      this.featuresSubject.next(this.featureRecord);
    }
  }

  /**
   * to validate features and permissions
   * @param features : list of features in form string array
   * @param permissions : list of permissions in form string array
   * @returns
   */
  validateFeaturesAndPermissions(
    features: string[] | undefined,
    permissions: string[] | undefined,
  ): boolean {
    if (features && features.length > 0) {
      const areFeaturesExist =
        this.showHideFeaturesBasedOnAvailability(features);
      if (!areFeaturesExist) {
        return false;
      }
    }
    //check does the permission exist or not
    if (permissions && permissions.length > 0) {
      const arePermissionsExist = this.areValidPermissions(permissions);
      if (!arePermissionsExist) {
        return false;
      }
    }
    return true;
  }

  /**
   * to validate features and permissions
   * @param features : list of features in form string array
   * @param permissions : list of permissions in form string array
   * @returns
   */
  validateFeaturesAndPermissionsForGuards(
    features: string[] | undefined,
    permissions: string[] | undefined,
  ): Observable<boolean> {
    return this.userDetailService.userCompactDetail.pipe(
      take(1),
      map((response) => {
        this.mapUserCompactData(response);
        return this.validateFeaturesAndPermissions(features, permissions);
      }),
    );
  }

  transformFeatures(features: FeatureModel[]): FeatureRecord {
    return features.reduce((acc: FeatureRecord, feature: FeatureModel) => {
      acc[feature.featureIdentifier] = feature.featureStatus === 'ACTIVE';
      return acc;
    }, {} as FeatureRecord);
  }

  showHideFeaturesBasedOnAvailability(featureIdentifiers: string[]) {
    const isfeatureRecordEmpty = Object.keys(this.featureRecord).length === 0;
    const fetchedFeatureRecordLS = this.fetchFeatureRecordsFromLocalStorage();
    let returnFeature = false;
    for (const featureIdentifier of featureIdentifiers) {
      const isFeatureAvailable = !isfeatureRecordEmpty
        ? this.featureRecord[featureIdentifier] != undefined
          ? this.featureRecord[featureIdentifier]
          : true
        : fetchedFeatureRecordLS &&
          fetchedFeatureRecordLS[featureIdentifier] != undefined
        ? fetchedFeatureRecordLS[featureIdentifier]
        : true;

      if (isFeatureAvailable) {
        returnFeature = true;
        break;
      }
    }
    return returnFeature;
  }

  fetchFeatureRecordsFromLocalStorage() {
    const featureRecordsFromLocalStroage =
      localStorage.getItem('featureRecords');
    if (featureRecordsFromLocalStroage) {
      const decryptedFeatureRecords = atob(featureRecordsFromLocalStroage);
      return JSON.parse(decryptedFeatureRecords);
    }
    return null;
  }

  /**
   * validate that at-least one input permissions should exist or not
   * @param acceptedPermissions : array of accepted permissions
   * @returns : boolean value, at-least one is valid or not
   */
  areValidPermissions(acceptedPermissions: string[]): boolean {
    // Should allow for superadmin and admin users
    if(acceptedPermissions.includes("global.superadmin") || acceptedPermissions.includes("global.admin")){
      return true;
    }
    // for other thean superadmin and admin
    if (this.currentPermissions && this.currentPermissions.length > 0) {
      for (const permission of this.currentPermissions) {
        if (acceptedPermissions.includes(permission)) return true;
      }
    }
    return false;
  }

  beforeLogin(features: string[] | undefined): Observable<boolean> {
    return this.startupService.beforeLoginFeatureList().pipe(
      take(1),
      switchMap((response) => {
        // If features are not already cached, fetch them
        if (!response) {
          return this.startupService
            .getAllFeatures()
            .pipe(
              map((fetchedData) =>
                this.validateFeatures(fetchedData, features),
              ),
            );
        }
        // Validate features using the cached response
        return of(this.validateFeatures(response, features));
      }),
    );
  }

  private validateFeatures(
    response: FeatureModel[],
    features: string[] | undefined,
  ): boolean {
    const featureList = this.transformFeatures(response);
    // Example: Dynamic validation for input features
    if (features && features.length > 0) {
      const featureKey = features[0];
      this.beforeLoginStatus = featureList[featureKey] || false;
    } else {
      this.beforeLoginStatus = false;
    }

    return this.beforeLoginStatus;
  }
}
