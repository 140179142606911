import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PRIVACY_POLICY_CONST } from '@app/core/constants/api.constant';
import { WEB_ANALYTICS_CONST } from '@app/core/constants/message.constant';
import { AnalyticsActivityTypeEnum } from '@app/shared/enum/analytics/analytics-activity-type.enum';
import { RolesEnum } from '@app/shared/enum/role-enum';
import {
  ActivityInsightModel,
  CountryResponseModel,
} from '@app/shared/models/analytics/user-activity.model';
import { CustomHttpResponse } from '@app/shared/models/custom-http-response.model';
import { BusinessAreaModel } from '@app/shared/models/manage-business-area/business-area.model';
import {
  CreditsModel,
  OrganisationCompactModel,
  PlansModel,
  RemainingCreditsListModel,
  RemainingCreditsModel,
  UserCompactModel,
} from '@app/shared/models/manage-user/user-compact-detail.model';
import { RevokePolicyRequest } from '@app/shared/models/terms-privacy/terms-privacy.model';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable, catchError, map, of } from 'rxjs';
import { CONSTANTS } from '../../constants/constant';
import { AnalyticsService } from '../analytics/analytics.service';
import { BusinessAreaService } from '../business-area/business-area.service';
import { ToastService } from '../toast/toast.service';

@Injectable({
  providedIn: 'root',
})
export class UserDetailService {
  constructor(
    private http: HttpClient,
    private analyticsService: AnalyticsService,
    private businessAreaService: BusinessAreaService,
    private toastService: ToastService,
  ) {
    //TODO: Need to remove this logic (old-one)
    const currentUserRoles = this.getCurrentUserRoles;
    if (currentUserRoles)
      this.currentUserHighestRole = currentUserRoles?.includes(
        RolesEnum.SUPERADMIN,
      )
        ? RolesEnum.SUPERADMIN
        : currentUserRoles?.includes(RolesEnum.ADMIN)
        ? RolesEnum.ADMIN
        : null;
    this.currentUserHighestRole = RolesEnum.ADMIN;

    //TODO: Add new logic on permissions from local-storage
  }

  // FIXME: [FIX-ROLES]: remove roles dependency from here, code refactoring
  public currentUserHighestRole: RolesEnum | null = null;

  userCompactDetail = new BehaviorSubject<UserCompactModel | null>(null);
  userCompactDetail$ = this.userCompactDetail.asObservable();

  userPlanDetail = new BehaviorSubject<PlansModel | null>(null);
  userPlanDetail$ = this.userPlanDetail.asObservable();

  public currentPermissions: string[] = [];
  userCountry = '';
  allBusinessAreasOfUser: BusinessAreaModel[] = [];
  selectedBusinessArea = new BehaviorSubject<BusinessAreaModel | null>(null);
  selectedBusinessArea$ = this.selectedBusinessArea.asObservable();

  private currentUserRolesSubject = new BehaviorSubject<RolesEnum[]>([]);
  currentUserRoles$ = this.currentUserRolesSubject.asObservable();
  public currentUserRoles: RolesEnum[] = [];
  isSuperAdminUser = false;

  // Update roles when business area changes
  setCurrentUserRolesEnum(roles: RolesEnum[]) {
    this.currentUserRolesSubject.next(roles);
  }

  getUserDetails(payload: { email: string }, forceApiCall = false) {
    if (this.userCompactDetail.getValue() && !forceApiCall) {
      return of({
        status_code: 200,
        data: this.userCompactDetail.getValue() as UserCompactModel,
        message: '',
      });
    }
    return this.http.post<CustomHttpResponse<UserCompactModel>>(
      CONSTANTS.API.USER_COMPACT_DETAIL,
      payload,
    );
  }

  getAndForceUpdateUserCompactView() {
    const userEmail = this.getEmailInLocalStorage;
    return this.getUserDetails({ email: userEmail }, true).pipe(
      map((res) => {
        if (res.status_code === 200) {
          this.emitCompactDetail(res.data);
        }
        return res;
      }),
    );
  }

  updateUserDetails(payload: {
    tokenId: string;
    email: string;
    firstName: string;
    lastName: string;
  }) {
    return this.http.put<CustomHttpResponse<UserCompactModel>>(
      CONSTANTS.API.UPDATE_USER_DETAIL,
      payload,
    );
  }

  // FIXME: [FIX-ROLES]: remove roles dependency from here, code refactoring
  get isAdminOrSuperAdmin() {
    if (
      this.currentPermissions?.includes('global.superadmin') ||
      this.currentPermissions?.includes('global.admin')
    ) {
      return true;
    }
    return false;
  }

  setBusinessArea(businessArea: BusinessAreaModel) {
    const uniquePermissionRoles =
      this.getAllUniqueRolesPermissions(businessArea);
    this.currentPermissions = uniquePermissionRoles.permissions;
    this.setCurrentUserRoles(uniquePermissionRoles.roles);
    this.selectedBusinessArea.next(businessArea);
    this.setUserBusinessArea(businessArea.id);
  }

  get getAllBusinessAreasOfUser() {
    return this.allBusinessAreasOfUser;
  }

  emitCompactDetail(
    data: UserCompactModel,
    analyticsType = AnalyticsActivityTypeEnum.WEB_TRAFFIC,
  ) {
    this.getBusinessArea(data);
    if (
      data &&
      (analyticsType === AnalyticsActivityTypeEnum.LOGIN ||
        analyticsType === AnalyticsActivityTypeEnum.REGISTRATION)
    ) {
      this.getUserCountry().subscribe({
        next: (country) => {
          this.setUserInfoInLocalStorage(
            data,
            this.currentUserRoles,
            country,
            analyticsType,
          );
        },
        error: () => {
          this.setUserInfoInLocalStorage(
            data,
            this.currentUserRoles,
            WEB_ANALYTICS_CONST.DEFAULT_COUNTRY,
            analyticsType,
          );
        },
      });
    }
    if (data?.userBusinessAreaGroups?.length > 0) {
      this.selectDefaultBusinessArea(data?.userBusinessAreaGroups?.[0]);
    }

    this.userCompactDetail.next(data);
    this.mapRemainingCreditsData(
      data?.userOrganisation?.purchasedProduct?.plan,
      data?.userOrganisation?.purchasedProduct?.remainingCredits,
    );
  }

  getBusinessArea(data: UserCompactModel) {
    // FIXME: Need refactoring required clarify on this.
    if (data.userBusinessAreaGroups && data.userBusinessAreaGroups.length > 0) {
      data.userBusinessAreaGroups = this.businessAreaService.setBALabel(
        data.userBusinessAreaGroups,
      );
      this.allBusinessAreasOfUser = data.userBusinessAreaGroups
        .filter((ba: BusinessAreaModel) => ba.partner !== true)
        .sort((a: BusinessAreaModel, b: BusinessAreaModel) => {
          if (a.default !== b.default) {
            return a.default ? -1 : 1;
          }
          return a.name.localeCompare(b.name);
        });
      this.selectDefaultBusinessArea(data.userBusinessAreaGroups[0]);
    } else {
      this.businessAreaService.getAllBusinessAreas().subscribe({
        next: (res) => {
          if (res.status_code === 200) {
            if (res.data.length > 0) {
              const defaultBa = res.data.filter((x) => x.default);
              data['userBusinessAreaGroups'] = defaultBa;
              this.allBusinessAreasOfUser = defaultBa;
              this.selectDefaultBusinessArea(defaultBa[0]);
              this.userCompactDetail.next(data);
            } else {
              this.toastService.error(res.message);
            }
          } else {
            this.toastService.error(res.message);
          }
        },
        error: (err) => {
          this.toastService.error(err.error ? err.error?.error : err.message);
        },
      });
    }
  }

  updateRemainingCredits(remainingCreditsData: RemainingCreditsModel) {
    const planData = this.userPlanDetail.getValue();
    if (remainingCreditsData?.remainingCreditsList) {
      this.mapRemainingCreditsData(
        planData,
        remainingCreditsData?.remainingCreditsList,
      );
    }
  }

  mapRemainingCreditsData(
    planData: PlansModel | null,
    remainingCreditList: RemainingCreditsListModel[],
  ) {
    if (planData && planData?.credits) {
      const formattedPlanData: CreditsModel[] = [];
      planData?.credits.forEach((credit) => {
        if (!credit.disabled) {
          const remainingCreditItem = remainingCreditList.find(
            (t) => credit.id === t.creditTypeId,
          );
          if (remainingCreditItem) {
            credit['remainingCredit'] = remainingCreditItem;
            formattedPlanData.push(credit);
          }
        }
      });
      planData['credits'] = formattedPlanData;
      this.userPlanDetail.next(planData);
    }
  }

  setUserInfoInLocalStorage(
    data: UserCompactModel,
    role: RolesEnum[] | null,
    country: string,
    analyticsType: AnalyticsActivityTypeEnum,
  ) {
    if (data) {
      const localUserOrgDetail: ActivityInsightModel = {
        user_id: data.userOrganisation?.userId || '',
        user_email: data.userModel.email || '',
        user_roles: role,
        user_country: country,
        company_name: data.userOrganisation?.organisationName || null,
        company_id: data.userOrganisation?.organisationId || null,
        activity_type: analyticsType,
        activity_date: new Date().toISOString(),
        authentication_mechanism: null,
        referrer_domain: null,
        error: null,
        error_code: null,
      };
      this.analyticsService.recordUserAnalytics(localUserOrgDetail).subscribe();
    }
  }

  getUserCountry(): Observable<string> {
    if (this.userCountry) return of(this.userCountry);
    const headers = { 'client-side-request': 'true' };
    return this.http
      .get<CountryResponseModel>(environment.countryApiUrl, { headers })
      .pipe(
        map((res: CountryResponseModel) => {
          // TODO: thinking of storing the user-country in browser. So, that it can be reused
          this.userCountry = res.country ?? '';
          return this.userCountry;
        }),
        catchError(() => {
          if (!this.userCountry) this.userCountry = '';
          return this.userCountry;
        }),
      );
  }

  revokeTermsPolicy(payload: RevokePolicyRequest) {
    return this.http.put<CustomHttpResponse<null>>(
      PRIVACY_POLICY_CONST.REVOKE_POLICY,
      payload,
    );
  }

  get getCurrentUserRoles(): RolesEnum[] {
    if (this.currentUserRoles && this.currentUserRoles.length > 0) {
      return this.currentUserRoles;
    } else if (typeof window !== 'undefined' && window.localStorage) {
      const encodedUserRoles = localStorage.getItem('userRoles') as string;
      const roles = encodedUserRoles
        ? JSON.parse(atob(encodedUserRoles))
        : ([] as RolesEnum[]);
      roles;
      return roles;
    }
    return [];
  }

  getCurrentUserRoles$(): Observable<RolesEnum[]> {
    return of(this.getCurrentUserRoles);
  }

  setCurrentUserRoles(roles: RolesEnum[]) {
    this.currentUserRoles = roles;
    if (this.currentUserRoles && this.currentUserRoles.length > 0) {
      if (typeof window !== 'undefined' && window.localStorage) {
        localStorage.setItem(
          'userRoles',
          btoa(JSON.stringify(this.currentUserRoles)),
        );
      }

      const rolesPriority: RolesEnum[] = [
        RolesEnum.SUPERADMIN,
        RolesEnum.ADMIN,
      ];

      this.currentUserHighestRole = null;

      for (const role of rolesPriority) {
        if (this.currentUserRoles.includes(role)) {
          this.currentUserHighestRole = role;
          break;
        }
      }
    }
  }

  checkEmailExistance(email: string): Observable<CustomHttpResponse<boolean>> {
    return this.http.get<CustomHttpResponse<boolean>>(
      CONSTANTS.API.CHECK_EMAIL_EXISTANCE + email,
    );
  }

  /**
   * Filter out unique permissions
   * @param businessArea : Array of business-areas including roles, including permissions
   * @returns return array of unique permissions
   */
  getAllUniqueRolesPermissions(businessArea: BusinessAreaModel): {
    roles: RolesEnum[];
    permissions: string[];
  } {
    const rolesArrSet: Set<string> = new Set();
    const permissionSet: Set<string> = new Set();
    businessArea.groups.forEach((group) => {
      group.roles.forEach((role) => {
        if (role?.name) rolesArrSet.add(role.name);
        role.permissions.forEach((permission) => {
          if (permission?.name) permissionSet.add(permission.name);
        });
      });
    });

    if (!businessArea.default) {
      const superadminAndAdminPermissions =
        this.getSuperadminAndAdminPermissions();
      const mergedRolesSet = new Set([
        ...superadminAndAdminPermissions.roles,
        ...rolesArrSet,
      ]);
      const mergedPermissionSet = new Set([
        ...superadminAndAdminPermissions.permissions,
        ...permissionSet,
      ]);
      this.isSuperAdminUser = this.checkIsSuperAdminUser(mergedPermissionSet);
      return {
        roles: (Array.from(mergedRolesSet) ?? []) as RolesEnum[],
        permissions: (Array.from(mergedPermissionSet) ?? []) as string[],
      };
    }

    this.isSuperAdminUser = this.checkIsSuperAdminUser(permissionSet);
    return {
      roles: (Array.from(rolesArrSet) ?? []) as RolesEnum[],
      permissions: (Array.from(permissionSet) ?? []) as string[],
    };
  }

  /**
   * Filter out unique permissions of SuperAdmin and Admin from default BA
   * @returns return array of unique permissions
   */
  getSuperadminAndAdminPermissions(): {
    roles: RolesEnum[];
    permissions: string[];
  } {
    const businessArea = this.allBusinessAreasOfUser.find((x) => x.default);

    const rolesArrSet: Set<string> = new Set();
    const permissionSet: Set<string> = new Set();

    // TODO: need refactoring
    if (businessArea) {
      for (const group of businessArea.groups) {
        if (this.isSuperadminPermissionAvailibityCheck(permissionSet)) {
          break;
        }
        for (const role of group.roles) {
          if (this.isSuperadminPermissionAvailibityCheck(permissionSet)) {
            break;
          }
          for (const permission of role.permissions) {
            if (
              permission?.name === 'global.superadmin' ||
              permission?.name === 'global.admin'
            ) {
              permissionSet.add(permission.name);
              rolesArrSet.add(role.name);
            }
            if (this.isSuperadminPermissionAvailibityCheck(permissionSet)) {
              break;
            }
          }
        }
      }
    }
    return {
      roles: (Array.from(rolesArrSet) ?? []) as RolesEnum[],
      permissions: (Array.from(permissionSet) ?? []) as string[],
    };
  }

  isSuperadminPermissionAvailibityCheck(permissionSet: Set<string>): boolean {
    return permissionSet.size == 2 || permissionSet.has('global.superadmin');
  }

  checkIsSuperAdminUser(currentPermissions: Set<string>) {
    return currentPermissions?.has('global.superadmin');
  }

  get userBusinessArea(): string {
    if (typeof window !== 'undefined' && window.localStorage) {
      const userBusinessArea = localStorage.getItem('userBusinessArea');
      return userBusinessArea ? JSON.parse(atob(userBusinessArea)) : '';
    }
    return '';
  }

  setUserBusinessArea(businessAreaId: string) {
    if (typeof window !== 'undefined' && window.localStorage) {
      localStorage.setItem(
        'userBusinessArea',
        btoa(JSON.stringify(businessAreaId)),
      );
    }
  }

  // Checks if the user's business area is in the list of business areas
  // else sets the passed business area
  selectDefaultBusinessArea(businessArea: BusinessAreaModel): void {
    const userBA = this.allBusinessAreasOfUser.find(
      (x) => x.id === this.userBusinessArea,
    );
    if (userBA) {
      this.setBusinessArea(userBA);
    } else {
      this.setBusinessArea(businessArea);
      this.setUserBusinessArea(businessArea.id);
    }
  }

  get getEmailInLocalStorage() {
    if (typeof localStorage !== 'undefined') {
      const getLocalValue = localStorage.getItem('email');
      return getLocalValue ? atob(getLocalValue) : '';
    }
    return '';
  }

  /**
   * Get organisation details saperately
   */
  getOrganisationDetails() {
    return this.http.get<CustomHttpResponse<OrganisationCompactModel>>(
      CONSTANTS.API.USER_ORGANISATION_DETAIL,
    );
  }
}
