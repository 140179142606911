export type AlertType = 'success' | 'info' | 'danger' | 'warning' | '';

// TODO: separate out the api and message constants file separately with specific sections exports separately
export const CONSTANTS = Object.freeze({
  API: {
    HELLO: 'health/hello',
    // USER_COMPACT_DETAIL: 'user-compact-view',
    USER_COMPACT_DETAIL: 'user-compact-view-v2',
    USER_ORGANISATION_DETAIL: 'org/details',
    CHECK_EMAIL_EXISTANCE: 'ensure-user-exists?userEmail=',
    UPDATE_USER_DETAIL: 'onboarding/users',
    SEND_FORGOT_PASSWORD_EMAIL: 'onboarding/tokens/users/forgot-password',
    TOKENS: 'onboarding/tokens',
    USER_TOKENS: 'onboarding/users/tokens',
    VERIFY_USER_INVITATION_TOKEN: 'onboarding/tokens/users/validate',
    VERIFY_USER_INVITATION_FIRST_LOGIN:
      'onboarding/tokens/users/first-login/reissue',

    VERIFY_USER_REJECT_ACTION: 'onboarding/users/declines-invitation',
    GATEWAY: {
      TEST_CONNECTION: 'gateway/connections/test',
      CONNECTION: 'gateway/connections',
      DELETE_CONNECTION: 'gateway/connections/delete',
      FORCE_DELETE_CONNECTION: 'gateway/connections/force-delete',
      GET_API_LIST: 'gateway/{id}/apis',
      GET_METADATA_CONNECTION_TYPES: 'gateway/metadata/connection-types',
      GET_CONNECTIONS: 'gateway/connections',
      FETCH_CONNECTIONS: 'gateway/connections/fetch-all',
      IMPORT_APIS: 'digital-content/apis/import',
      IMPORT_IFLOW: 'digital-content/iflows/import',
      GET_REGIONS_AND_COUNTRIES: 'gateway/regions-and-countries',
    },
    GATEWAY_ANALYTICS: {
      TEST_CONNECTION: 'gateway-analytics/connections/test',
      CONNECTION: 'gateway-analytics/connections',
      DELETE_CONNECTION: 'gateway-analytics/connections/delete',
      GET_API_LIST: 'gateway-analytics/{id}/apis',
      GET_METADATA_CONNECTION_TYPES:
        'gateway-analytics/metadata/connection-types',
      GET_CONNECTIONS: 'gateway-analytics/connections',
    },
    ANALYTICS: {
      GET_CONFIG: 'org/settings/analytics',
      USER_ACTIVITY: 'analytics/user-activity',
    },
    SETTINGS: {
      GET_SETTING_API: 'org/settings',
      CHECK_ORG_ID: 'org/validate',
      APP_VERSION: 'org/app/version',
    },
    CONTENT_TYPES: {
      GET_CONTENT_TYPES: 'org/content-types',
    },
    REGISTRATION: {
      NEW_USER_SIGNUP: 'users/signup/external',
      CHECK_CREDITS: 'org/user-remaining-credits',
      PUBLIC_PORTALS: 'org/settings/public-portals',
      CHECK_VALIDITY_NAME: 'onboarding/partner-organisation/name',
      CHECK_VALIDITY_ID:
        'onboarding/partner-organisation/company-registered-id',
    },
  },
  MESSAGES: {
    FILL_ALL_DETAILS: 'Please fill all the details',
    FILL_ALL_REQUIRED_DETAILS: 'Please fill all the required details',
    VALIDATE_FILE: 'Please validate the file before saving',
    LOGGED_IN: 'Hey, you are logged in!',
    SIGN_IN: 'Hey, you are signed up!',
    NAME_UPDATED: 'Name update successfully.',
    SOMETHING_WENT_WRONG: 'Something went wrong. Please try again.',
    CUSTOM_TOKEN_AUTH_FAILED: 'Authentication failed.',
    CUSTOM_TOKEN_ERROR: 'Failed to retrieve custom token.',
    INTERNAL_SERVER_ERROR: 'Internal server error',
    ERROR_CONVERTING_BASE_64: 'Error converting file into base64.',
    CONNECTION_DELETED_SUCCESSFULLY: 'Connection deleted successfully.',
    VERIFY_USER_INVALID_ROUTE: 'Invalid the route. Please check your email.',
    VERIFY_USER_LOADING: 'Please wait we are validating.',
    VERIFY_TOKEN_EXPIRED:
      'Token is expired. Please click on the button below to regenerate the token.',
    VERIFY_ORGANISATION_NOT_FOUND:
      'Organisation not found. Please contact admin for more information.',
    VERIFY_PRODUCT_NOT_FOUND:
      'Product not found. Please contact admin for more information.',
    VERIFY_TOKEN_NOT_FOUND:
      'Token is missing. Please check you email once again or contact admin for more information.',
    VERIFY_TOKEN_DOES_NOT_BELONG_TO_USER:
      'Token is invalid. Please contact admin for more information.',
    VERIFY_TOKEN_IS_VALID: 'You are is verified. Get ready for signup.',
    VERIFY_USER_DOES_NOT_BELONG_TO_ORGANISATION:
      'You are not verified. There is some issue with the organisation, Please contact admin for more information.',
    VERIFY_USER_DOES_NOT_HAVE_ACCESS_TO_PRODUCT: `You are not verified. You don't have access to this product. Please contact admin for more information.`,
    VERIFY_REJECT_VALIDATE_SUCCESS:
      'If you want to join back then you can contact to admin.',
    VERIFY_REJECT_VALIDATE_FAILED:
      'An error occurred while trying to send the admin an email informing that the user rejected their invitation.',
    VERIFY_TOKEN_REISSUE_SUCCESSFULLY:
      'A new token has been generated successfully and sent to your email.',
    VERIFY_TOKEN_REISSUE_IS_ERROR: 'Error generating a token, Try again.',

    PASSWORD_STRENGTH: {
      MINLENGTH: 'Minimum length of 8 characters',
      MAXLENGTH: 'Maximum length of 128 characters',
      LOWERCASE: 'At least one lowercase letter',
      UPPERCASE: 'At least one uppercase letter',
      NUMBER: 'At least one numeric digit',
      SYMBOL: 'At least one special character',
    },
    SEND_FORGOT_PASSWORD_EMAIL_SUCCESS: `If you have an account with us. You will receive an email to reset the password.`,
    CHANGE_PASSWORD: {
      UPDATED_SUCCESSFULLY: 'Password updated successfully.',
    },
    AUTH: {
      'auth/weak-password': 'Password is too short.',
      'auth/email-already-in-use': 'Email address already in use.',
      'auth/email-already-exists': 'Email already exist.',
      'auth/id-token-expired': 'Token expired, Please logout and login again.',
      'auth/id-token-revoked':
        'User revoked, Please logout and login again or contact admin.',
      'auth/invalid-email': 'You have entered incorrect email.',
      'auth/invalid-password': 'You have entered incorrect password.',
      'auth/wrong-password': 'You have entered incorrect password.',
      'auth/user-not-found': 'User not found.',
      'auth/too-many-requests':
        'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.',
      'auth/invalid-action-code':
        'You are not verified. Check your email once again and validate your token.',
      'auth/invalid-login-credentials':
        'Login failed: Invalid user name or password',
    },
    SSO: {
      SAML_AUTH_ERROR: 'Error during SAML authentication',
      OPENID_AUTH_ERROR: 'Error during OPENID authentication',
      SAML_CALBACK_ERROR:
        'The SAML login response is missing. Please try logging in again.',
      OPENID_CALLBACK_ERROR:
        'The OPENID login response is missing. Please try logging in again.',
    },
    VERIFY_USER_ANALYTICS_ACCESS:
      'You do not have permission to view this page. Please contact admin for more information.',
    WORKFLOW: {
      WORKFLOW_SETTINGS_UPDATED: 'Workflow settings updated.',
      WORKFLOW_SETTINGS_ERROR: 'Failed to load workflow settings.',
      WORKFLOW_SETTINGS_UPDATE_FAILED: 'Failed to update workflow settings.',
    },
    MANAGE_GROUPS: {
      GROUP_NOT_FOUND: 'Group not found.',
    },
  },
});
