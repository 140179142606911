<div class="border__right px-3">
  <div class="d-flex align-items-center position-relative">
    <span class="business-area-header font__regular fs__12">Business Area</span>
  </div>
  <div
    data-testid="header-dropdown-ba-action"
    *ngIf="true"
    class="d-flex align-items-center position-relative cursor-pointer"
    dropdown
    dropdownToggle
  >
    <ng-container *ngIf="businessAreas.length > 1; else singleBusinessAreaUser">
      <!-- Header Section -->
      <p
        class="mb-0 d-block business-area-header-name font__ellipsis fs__14"
        *ngIf="selectedBusinessArea"
      >
        <span
          class="header__text"
          data-testid="header-dropdown-ba-name"
          [title]="selectedBusinessArea.name"
        >
          {{ selectedBusinessArea.displayName || selectedBusinessArea.name }}
        </span>
      </p>
      <i class="icommon icon-arrow-right icon__down text__body my-0"></i>

      <!-- Dropdown Menu -->
      <ul
        id="dropdown-plan"
        *dropdownMenu
        class="i-dropdown-menu dropdown-menu dropdown-menu-right ba-dropdown"
        role="menu"
        aria-labelledby="button-plan"
        placement="left"
        data-testid="header-dropdown-user-menu"
      >
        <ng-container *ngFor="let ba of businessAreas; let i = index">
          <li role="menuitem">
            <a
              class="dropdown-item"
              [class.active]="selectedBusinessArea?.id === ba.id"
              (click)="onChangeBusinessArea(ba)"
              (keydown.enter)="onChangeBusinessArea(ba)"
              [tabindex]="i"
            >
              {{ ba.displayName || ba.name }}
            </a>
          </li>
          <!-- Add divider except for the last item -->
          <li *ngIf="i < businessAreas.length - 1">
            <hr class="dropdown-divider" />
          </li>
        </ng-container>
      </ul>
    </ng-container>

    <!-- Fallback Template for Single Business Area -->
    <ng-template #singleBusinessAreaUser>
      <p class="mb-0 d-block">
        <span
          class="header__text mb-0 d-block business-area-header-name font__ellipsis fs__14"
          data-testid="header-dropdown-ba-name"
          *ngIf="selectedBusinessArea"
          [title]="
            selectedBusinessArea.displayName || selectedBusinessArea.name
          "
        >
          {{ selectedBusinessArea.displayName || selectedBusinessArea.name }}
        </span>
      </p>
    </ng-template>
  </div>
</div>
